import classNames from "classnames"
import once from "lodash/once"
import * as React from "react"
import { AudioPlayer } from "react-audio-play"

type FCWithClass = React.FC<{ className?: string; children: React.ReactNode }>

export const BlockQuote: FCWithClass = ({ children }) => {
  return (
    <blockquote
      className="n-color-mid n-vspacing-small n-type-normal n-spacing-left-medium "
      style={{ fontStyle: "italic" }}
    >
      {children}
    </blockquote>
  )
}

export const UL: FCWithClass = ({ children }) => {
  return <ul className="diamond-list n-vspacing-small">{children}</ul>
}
export const OL: FCWithClass = ({ children }) => {
  return <ol className="g-numbered-list n-vspacing-small">{children}</ol>
}
export const HR: FCWithClass = () => {
  return <hr role="presentation" className="hr n-vspacing-large" />
}
export const Pre: FCWithClass = ({ children, className }) => {
  require("../css/components/prism.scss")
  return (
    <pre className={`n-vspacing-medium n-type-small g-popout ${className}`}>
      {children}
    </pre>
  )
}

export const H1: FCWithClass = (props) => {
  return (
    <h1
      {...props}
      className="n-spacing-bottom-small n-spacing-top-medium n-text-centered"
    />
  )
}

export const H2: FCWithClass = (props) => {
  return (
    <h2
      {...props}
      className="n-spacing-bottom-tiny n-spacing-top-medium n-text-centered"
    />
  )
}

export const H3: FCWithClass = (props) => {
  return (
    <h3 className="n-spacing-bottom-tiny n-spacing-top-medium" {...props} />
  )
}

export const Audio: React.FC<
  React.HTMLProps<HTMLAudioElement> & { backgroundColor?: string }
> = (props) => {
  const firstSource = React.Children.toArray(props.children)[0] as any
  const audioSrc = props.src ?? firstSource?.props?.src

  const onError = once(() => {
    if (typeof umami !== "undefined")
      umami?.trackEvent(audioSrc, "⛔️ audio error")
  })

  const onPlay = once(() => {
    if (typeof umami !== "undefined") umami?.trackEvent(audioSrc, "🎵")
  })

  return (
    <AudioPlayer
      src={audioSrc}
      color="#333333"
      sliderColor="hsl(215, 68%, 59%)"
      onError={onError}
      onPlay={onPlay}
      backgroundColor={props.backgroundColor ?? "rgba(233, 237, 240, 0.7)"}
      className={classNames(props.className, "audio")}
      width={props.width}
      style={{ borderRadius: 10, height: 45 }}
      preload={props.preload as any}
    />
  )
}

export const Aside: FCWithClass = (props) => {
  return (
    <aside
      className={classNames(
        "n-vspacing-medium n-padding-small n-border-accent",
        props.className
      )}
    >
      {props.children}
    </aside>
  )
}
